// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-booknow-js": () => import("./../../../src/pages/booknow.js" /* webpackChunkName: "component---src-pages-booknow-js" */),
  "component---src-pages-classes-advanced-reformer-js": () => import("./../../../src/pages/classes/advanced_reformer.js" /* webpackChunkName: "component---src-pages-classes-advanced-reformer-js" */),
  "component---src-pages-classes-barre-burn-js": () => import("./../../../src/pages/classes/barre_burn.js" /* webpackChunkName: "component---src-pages-classes-barre-burn-js" */),
  "component---src-pages-classes-beginner-reformer-js": () => import("./../../../src/pages/classes/beginner_reformer.js" /* webpackChunkName: "component---src-pages-classes-beginner-reformer-js" */),
  "component---src-pages-classes-improvers-reformer-js": () => import("./../../../src/pages/classes/improvers_reformer.js" /* webpackChunkName: "component---src-pages-classes-improvers-reformer-js" */),
  "component---src-pages-classes-js": () => import("./../../../src/pages/classes.js" /* webpackChunkName: "component---src-pages-classes-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

